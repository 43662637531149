/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const azingoBookingConfirmation = /* GraphQL */ `
  mutation AzingoBookingConfirmation($bookingID: String!, $status: String!) {
    azingoBookingConfirmation(bookingID: $bookingID, status: $status)
  }
`;
export const azingoBookingNewCustomer = /* GraphQL */ `
  mutation AzingoBookingNewCustomer(
    $serviceID: String!
    $timeSlotID: String
    $email: String!
    $bookingDate: String
    $locations: [LocationInput]
    $teamID: String
  ) {
    azingoBookingNewCustomer(
      serviceID: $serviceID
      timeSlotID: $timeSlotID
      email: $email
      bookingDate: $bookingDate
      locations: $locations
      teamID: $teamID
    )
  }
`;
export const azingoBookingTeamAvailability = /* GraphQL */ `
  mutation AzingoBookingTeamAvailability(
    $contractID: String
    $stripeCustomerID: String
    $teamAvailabilityID: String!
    $teamID: String!
    $startDate: String!
    $endDate: String!
    $location: LocationInput
    $autoConfirm: Boolean
  ) {
    azingoBookingTeamAvailability(
      contractID: $contractID
      stripeCustomerID: $stripeCustomerID
      teamAvailabilityID: $teamAvailabilityID
      teamID: $teamID
      startDate: $startDate
      endDate: $endDate
      location: $location
      autoConfirm: $autoConfirm
    )
  }
`;
export const azingoBookingTimeSlot = /* GraphQL */ `
  mutation AzingoBookingTimeSlot(
    $contractID: String!
    $timeSlotIDs: [String]!
    $location: LocationInput
  ) {
    azingoBookingTimeSlot(
      contractID: $contractID
      timeSlotIDs: $timeSlotIDs
      location: $location
    )
  }
`;
export const azingoCancelSubscription = /* GraphQL */ `
  mutation AzingoCancelSubscription(
    $contractID: String!
    $cancelReason: String
  ) {
    azingoCancelSubscription(
      contractID: $contractID
      cancelReason: $cancelReason
    )
  }
`;
export const azingoTeamAvailabilityCreate = /* GraphQL */ `
  mutation AzingoTeamAvailabilityCreate(
    $id: String!
    $startDate: String!
    $endDate: String!
    $bookingDuration: Int!
    $bookingBufferAfter: Int
    $bookingBufferBefore: Int
    $organizationID: String!
    $locations: [LocationInput]
    $teamIDs: [String]!
    $serviceID: String!
    $autoConfirm: Boolean
  ) {
    azingoTeamAvailabilityCreate(
      id: $id
      startDate: $startDate
      endDate: $endDate
      bookingDuration: $bookingDuration
      bookingBufferAfter: $bookingBufferAfter
      bookingBufferBefore: $bookingBufferBefore
      organizationID: $organizationID
      locations: $locations
      teamIDs: $teamIDs
      serviceID: $serviceID
      autoConfirm: $autoConfirm
    )
  }
`;
export const azingoTeammateCreate = /* GraphQL */ `
  mutation AzingoTeammateCreate(
    $organizationID: String!
    $name: String!
    $email: String!
    $roles: [TeammateRoleType!]
    $locations: [LocationInput]
    $picture: String
  ) {
    azingoTeammateCreate(
      organizationID: $organizationID
      name: $name
      email: $email
      roles: $roles
      locations: $locations
      picture: $picture
    )
  }
`;
export const azingoTimeSlotCreate = /* GraphQL */ `
  mutation AzingoTimeSlotCreate(
    $id: String!
    $title: String
    $description: String
    $startDate: String!
    $endDate: String!
    $type: TimeSlotType!
    $maxAttendees: Int
    $attendingContracts: [String]
    $deliveryType: ServiceDeliveryType
    $deliveryOptions: [OnlineDeliveryInput]
    $registrationType: ServiceRegistrationType
    $location: LocationInput
    $locationRestrictions: [LocationRestrictionInput]
    $organizationID: String!
    $serviceID: String
    $teamID: String
    $resourceIDs: [String]
    $details: AWSJSON
  ) {
    azingoTimeSlotCreate(
      id: $id
      title: $title
      description: $description
      startDate: $startDate
      endDate: $endDate
      type: $type
      maxAttendees: $maxAttendees
      attendingContracts: $attendingContracts
      deliveryType: $deliveryType
      deliveryOptions: $deliveryOptions
      registrationType: $registrationType
      location: $location
      locationRestrictions: $locationRestrictions
      organizationID: $organizationID
      serviceID: $serviceID
      teamID: $teamID
      resourceIDs: $resourceIDs
      details: $details
    )
  }
`;
export const azingoTimeSlotBookingCreate = /* GraphQL */ `
  mutation AzingoTimeSlotBookingCreate(
    $organizationID: String!
    $startDate: String!
    $endDate: String!
    $teamID: String!
    $serviceID: String
    $resourceIDs: [String]
    $details: AWSJSON!
    $contractID: String!
    $bookingType: BookingType!
  ) {
    azingoTimeSlotBookingCreate(
      organizationID: $organizationID
      startDate: $startDate
      endDate: $endDate
      teamID: $teamID
      serviceID: $serviceID
      resourceIDs: $resourceIDs
      details: $details
      contractID: $contractID
      bookingType: $bookingType
    )
  }
`;
export const azingoTeamAvailabilityPurge = /* GraphQL */ `
  mutation AzingoTeamAvailabilityPurge(
    $organizationID: String!
    $serviceID: String!
  ) {
    azingoTeamAvailabilityPurge(
      organizationID: $organizationID
      serviceID: $serviceID
    )
  }
`;
export const azingoStripeAccountCreate = /* GraphQL */ `
  mutation AzingoStripeAccountCreate(
    $stripeAccountParams: AWSJSON!
    $appReturnURL: String
    $organizationID: String
  ) {
    azingoStripeAccountCreate(
      stripeAccountParams: $stripeAccountParams
      appReturnURL: $appReturnURL
      organizationID: $organizationID
    )
  }
`;
export const azingoStripeAccountLinkCreate = /* GraphQL */ `
  mutation AzingoStripeAccountLinkCreate(
    $stripeAccountID: String!
    $linkType: String!
    $refreshURL: String
    $returnURL: String
  ) {
    azingoStripeAccountLinkCreate(
      stripeAccountID: $stripeAccountID
      linkType: $linkType
      refreshURL: $refreshURL
      returnURL: $returnURL
    )
  }
`;
export const azingoStripeInvoiceCreate = /* GraphQL */ `
  mutation AzingoStripeInvoiceCreate(
    $organizationID: String!
    $stripeCustomerID: String!
    $unit_amount: Int!
    $quantity: Int
    $currency: String
    $description: String
    $days_until_due: String
    $metadata: AWSJSON
  ) {
    azingoStripeInvoiceCreate(
      organizationID: $organizationID
      stripeCustomerID: $stripeCustomerID
      unit_amount: $unit_amount
      quantity: $quantity
      currency: $currency
      description: $description
      days_until_due: $days_until_due
      metadata: $metadata
    )
  }
`;
export const azingoStripeQuoteCreate = /* GraphQL */ `
  mutation AzingoStripeQuoteCreate(
    $organizationID: String!
    $serviceID: String!
    $currency: String!
    $unit_amount_decimal: Int!
    $quantity: Int
    $description: String
    $couponID: String
    $discountID: String
    $expires_at: Int
    $days_until_due: Int
    $metadata: AWSJSON
    $stripeCustomerID: String
    $customerName: String
    $customerEmail: String
    $customerPhone: String
    $customerAddress: AWSJSON
  ) {
    azingoStripeQuoteCreate(
      organizationID: $organizationID
      serviceID: $serviceID
      currency: $currency
      unit_amount_decimal: $unit_amount_decimal
      quantity: $quantity
      description: $description
      couponID: $couponID
      discountID: $discountID
      expires_at: $expires_at
      days_until_due: $days_until_due
      metadata: $metadata
      stripeCustomerID: $stripeCustomerID
      customerName: $customerName
      customerEmail: $customerEmail
      customerPhone: $customerPhone
      customerAddress: $customerAddress
    )
  }
`;
export const azingoStripeQuoteUpdate = /* GraphQL */ `
  mutation AzingoStripeQuoteUpdate(
    $organizationID: String!
    $quoteID: String!
    $currency: String
    $serviceID: String
    $unit_amount_decimal: Int
    $description: String
    $quantity: Int
    $couponID: String
    $discountID: String
    $expires_at: Int
    $days_until_due: Int
    $metadata: AWSJSON
  ) {
    azingoStripeQuoteUpdate(
      organizationID: $organizationID
      quoteID: $quoteID
      currency: $currency
      serviceID: $serviceID
      unit_amount_decimal: $unit_amount_decimal
      description: $description
      quantity: $quantity
      couponID: $couponID
      discountID: $discountID
      expires_at: $expires_at
      days_until_due: $days_until_due
      metadata: $metadata
    )
  }
`;
export const azingoStripeQuoteStatusUpdate = /* GraphQL */ `
  mutation AzingoStripeQuoteStatusUpdate(
    $organizationID: String!
    $quoteID: String!
    $status: QuoteStatus!
  ) {
    azingoStripeQuoteStatusUpdate(
      organizationID: $organizationID
      quoteID: $quoteID
      status: $status
    )
  }
`;
export const azingoStripeCustomerCreate = /* GraphQL */ `
  mutation AzingoStripeCustomerCreate(
    $organizationID: String!
    $email: String!
    $name: String!
    $options: AWSJSON
  ) {
    azingoStripeCustomerCreate(
      organizationID: $organizationID
      email: $email
      name: $name
      options: $options
    )
  }
`;
export const azingoGoogleCalendarEventCreate = /* GraphQL */ `
  mutation AzingoGoogleCalendarEventCreate(
    $refreshToken: String
    $accessToken: String
    $calendarID: String!
    $title: String!
    $description: String
    $startDate: String!
    $endDate: String!
    $timezone: String!
    $location: String
    $attendees: [GoogleCalendarEventAttendees]
  ) {
    azingoGoogleCalendarEventCreate(
      refreshToken: $refreshToken
      accessToken: $accessToken
      calendarID: $calendarID
      title: $title
      description: $description
      startDate: $startDate
      endDate: $endDate
      timezone: $timezone
      location: $location
      attendees: $attendees
    )
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      type
      details
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      type
      details
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      type
      details
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAffiliateLink = /* GraphQL */ `
  mutation CreateAffiliateLink(
    $input: CreateAffiliateLinkInput!
    $condition: ModelAffiliateLinkConditionInput
  ) {
    createAffiliateLink(input: $input, condition: $condition) {
      id
      affiliateProductID
      azingoAffiliateProduct {
        id
        src
        categoryIDs
        countrySourceMap {
          country
          src
          __typename
        }
        type
        title
        subtitle
        description
        keywords
        thumbnail
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      categoryIDs
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAffiliateLink = /* GraphQL */ `
  mutation UpdateAffiliateLink(
    $input: UpdateAffiliateLinkInput!
    $condition: ModelAffiliateLinkConditionInput
  ) {
    updateAffiliateLink(input: $input, condition: $condition) {
      id
      affiliateProductID
      azingoAffiliateProduct {
        id
        src
        categoryIDs
        countrySourceMap {
          country
          src
          __typename
        }
        type
        title
        subtitle
        description
        keywords
        thumbnail
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      categoryIDs
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAffiliateLink = /* GraphQL */ `
  mutation DeleteAffiliateLink(
    $input: DeleteAffiliateLinkInput!
    $condition: ModelAffiliateLinkConditionInput
  ) {
    deleteAffiliateLink(input: $input, condition: $condition) {
      id
      affiliateProductID
      azingoAffiliateProduct {
        id
        src
        categoryIDs
        countrySourceMap {
          country
          src
          __typename
        }
        type
        title
        subtitle
        description
        keywords
        thumbnail
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      categoryIDs
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAffiliateProduct = /* GraphQL */ `
  mutation CreateAffiliateProduct(
    $input: CreateAffiliateProductInput!
    $condition: ModelAffiliateProductConditionInput
  ) {
    createAffiliateProduct(input: $input, condition: $condition) {
      id
      src
      categoryIDs
      countrySourceMap {
        country
        src
        __typename
      }
      type
      title
      subtitle
      description
      keywords
      thumbnail
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAffiliateProduct = /* GraphQL */ `
  mutation UpdateAffiliateProduct(
    $input: UpdateAffiliateProductInput!
    $condition: ModelAffiliateProductConditionInput
  ) {
    updateAffiliateProduct(input: $input, condition: $condition) {
      id
      src
      categoryIDs
      countrySourceMap {
        country
        src
        __typename
      }
      type
      title
      subtitle
      description
      keywords
      thumbnail
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAffiliateProduct = /* GraphQL */ `
  mutation DeleteAffiliateProduct(
    $input: DeleteAffiliateProductInput!
    $condition: ModelAffiliateProductConditionInput
  ) {
    deleteAffiliateProduct(input: $input, condition: $condition) {
      id
      src
      categoryIDs
      countrySourceMap {
        country
        src
        __typename
      }
      type
      title
      subtitle
      description
      keywords
      thumbnail
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAffiliateLinkRequest = /* GraphQL */ `
  mutation CreateAffiliateLinkRequest(
    $input: CreateAffiliateLinkRequestInput!
    $condition: ModelAffiliateLinkRequestConditionInput
  ) {
    createAffiliateLinkRequest(input: $input, condition: $condition) {
      id
      url
      message
      organizationID
      organization {
        id
        name
        handle
        type
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        keywords
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        hide
        cloned
        cloneConfig
        entitlements
        purchaserID
        planID
        paymentConfig
        onboarding
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      teammateID
      teammate {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      userID
      status
      hide
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAffiliateLinkRequest = /* GraphQL */ `
  mutation UpdateAffiliateLinkRequest(
    $input: UpdateAffiliateLinkRequestInput!
    $condition: ModelAffiliateLinkRequestConditionInput
  ) {
    updateAffiliateLinkRequest(input: $input, condition: $condition) {
      id
      url
      message
      organizationID
      organization {
        id
        name
        handle
        type
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        keywords
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        hide
        cloned
        cloneConfig
        entitlements
        purchaserID
        planID
        paymentConfig
        onboarding
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      teammateID
      teammate {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      userID
      status
      hide
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAffiliateLinkRequest = /* GraphQL */ `
  mutation DeleteAffiliateLinkRequest(
    $input: DeleteAffiliateLinkRequestInput!
    $condition: ModelAffiliateLinkRequestConditionInput
  ) {
    deleteAffiliateLinkRequest(input: $input, condition: $condition) {
      id
      url
      message
      organizationID
      organization {
        id
        name
        handle
        type
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        keywords
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        hide
        cloned
        cloneConfig
        entitlements
        purchaserID
        planID
        paymentConfig
        onboarding
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      teammateID
      teammate {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      userID
      status
      hide
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      type
      bookingDate
      email
      suppressEmail
      timeSlotID
      organizationID
      contractID
      serviceID
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      contract {
        id
        name
        userID
        email
        phoneNumber
        creditBalance
        priceID
        price {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        activeDate
        formattedActiveDate
        active
        status
        duration
        address {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        type
        cancelReason
        bundleID
        serviceID
        stripeCustomerID
        stripePaymentIntentID
        stripeSubscriptionID
        organizationID
        notificationEmailList
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      type
      bookingDate
      email
      suppressEmail
      timeSlotID
      organizationID
      contractID
      serviceID
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      contract {
        id
        name
        userID
        email
        phoneNumber
        creditBalance
        priceID
        price {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        activeDate
        formattedActiveDate
        active
        status
        duration
        address {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        type
        cancelReason
        bundleID
        serviceID
        stripeCustomerID
        stripePaymentIntentID
        stripeSubscriptionID
        organizationID
        notificationEmailList
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      type
      bookingDate
      email
      suppressEmail
      timeSlotID
      organizationID
      contractID
      serviceID
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      contract {
        id
        name
        userID
        email
        phoneNumber
        creditBalance
        priceID
        price {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        activeDate
        formattedActiveDate
        active
        status
        duration
        address {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        type
        cancelReason
        bundleID
        serviceID
        stripeCustomerID
        stripePaymentIntentID
        stripeSubscriptionID
        organizationID
        notificationEmailList
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBookingRestrictions = /* GraphQL */ `
  mutation CreateBookingRestrictions(
    $input: CreateBookingRestrictionsInput!
    $condition: ModelBookingRestrictionsConditionInput
  ) {
    createBookingRestrictions(input: $input, condition: $condition) {
      id
      nextAvailableDate
      lastFrequencyDate
      frequencySlotsAvailable
      totalSlotsAvailable
      contractID
      serviceID
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBookingRestrictions = /* GraphQL */ `
  mutation UpdateBookingRestrictions(
    $input: UpdateBookingRestrictionsInput!
    $condition: ModelBookingRestrictionsConditionInput
  ) {
    updateBookingRestrictions(input: $input, condition: $condition) {
      id
      nextAvailableDate
      lastFrequencyDate
      frequencySlotsAvailable
      totalSlotsAvailable
      contractID
      serviceID
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBookingRestrictions = /* GraphQL */ `
  mutation DeleteBookingRestrictions(
    $input: DeleteBookingRestrictionsInput!
    $condition: ModelBookingRestrictionsConditionInput
  ) {
    deleteBookingRestrictions(input: $input, condition: $condition) {
      id
      nextAvailableDate
      lastFrequencyDate
      frequencySlotsAvailable
      totalSlotsAvailable
      contractID
      serviceID
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBundle = /* GraphQL */ `
  mutation CreateBundle(
    $input: CreateBundleInput!
    $condition: ModelBundleConditionInput
  ) {
    createBundle(input: $input, condition: $condition) {
      id
      priceIDs
      prices {
        items {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      totalCredits
      organizationID
      bundleWeights {
        serviceID
        credits
        __typename
      }
      serviceIDs
      services {
        items {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBundle = /* GraphQL */ `
  mutation UpdateBundle(
    $input: UpdateBundleInput!
    $condition: ModelBundleConditionInput
  ) {
    updateBundle(input: $input, condition: $condition) {
      id
      priceIDs
      prices {
        items {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      totalCredits
      organizationID
      bundleWeights {
        serviceID
        credits
        __typename
      }
      serviceIDs
      services {
        items {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBundle = /* GraphQL */ `
  mutation DeleteBundle(
    $input: DeleteBundleInput!
    $condition: ModelBundleConditionInput
  ) {
    deleteBundle(input: $input, condition: $condition) {
      id
      priceIDs
      prices {
        items {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      totalCredits
      organizationID
      bundleWeights {
        serviceID
        credits
        __typename
      }
      serviceIDs
      services {
        items {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      displayOrder
      isDeleted
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      displayOrder
      isDeleted
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      displayOrder
      isDeleted
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      id
      organizationID
      contractIDs
      serviceIDs
      uploadedBy
      src
      type
      mimeType
      title
      subtitle
      description
      thumbnail
      tagline
      storageType
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      organizationID
      contractIDs
      serviceIDs
      uploadedBy
      src
      type
      mimeType
      title
      subtitle
      description
      thumbnail
      tagline
      storageType
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      id
      organizationID
      contractIDs
      serviceIDs
      uploadedBy
      src
      type
      mimeType
      title
      subtitle
      description
      thumbnail
      tagline
      storageType
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      name
      userID
      email
      phoneNumber
      creditBalance
      priceID
      price {
        id
        active
        currency
        name
        paymentModel
        paymentRef
        productRef
        frequency
        scheme
        type
        depositMethod
        hourlyRate
        hours
        depositAmount
        depositPercentage
        amount
        tiers {
          name
          amount
          maxQuantity
          shareable
          shareableQuantity
          __typename
        }
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      activeDate
      formattedActiveDate
      active
      status
      duration
      address {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      type
      cancelReason
      bundleID
      serviceID
      stripeCustomerID
      stripePaymentIntentID
      stripeSubscriptionID
      organizationID
      notificationEmailList
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      name
      userID
      email
      phoneNumber
      creditBalance
      priceID
      price {
        id
        active
        currency
        name
        paymentModel
        paymentRef
        productRef
        frequency
        scheme
        type
        depositMethod
        hourlyRate
        hours
        depositAmount
        depositPercentage
        amount
        tiers {
          name
          amount
          maxQuantity
          shareable
          shareableQuantity
          __typename
        }
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      activeDate
      formattedActiveDate
      active
      status
      duration
      address {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      type
      cancelReason
      bundleID
      serviceID
      stripeCustomerID
      stripePaymentIntentID
      stripeSubscriptionID
      organizationID
      notificationEmailList
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      name
      userID
      email
      phoneNumber
      creditBalance
      priceID
      price {
        id
        active
        currency
        name
        paymentModel
        paymentRef
        productRef
        frequency
        scheme
        type
        depositMethod
        hourlyRate
        hours
        depositAmount
        depositPercentage
        amount
        tiers {
          name
          amount
          maxQuantity
          shareable
          shareableQuantity
          __typename
        }
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      activeDate
      formattedActiveDate
      active
      status
      duration
      address {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      type
      cancelReason
      bundleID
      serviceID
      stripeCustomerID
      stripePaymentIntentID
      stripeSubscriptionID
      organizationID
      notificationEmailList
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      isRead
      details
      organizationID
      teamID
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      isRead
      details
      organizationID
      teamID
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      isRead
      details
      organizationID
      teamID
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      handle
      type
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      keywords
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      hide
      cloned
      cloneConfig
      entitlements
      purchaserID
      planID
      paymentConfig
      onboarding
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      handle
      type
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      keywords
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      hide
      cloned
      cloneConfig
      entitlements
      purchaserID
      planID
      paymentConfig
      onboarding
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      handle
      type
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      keywords
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      hide
      cloned
      cloneConfig
      entitlements
      purchaserID
      planID
      paymentConfig
      onboarding
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createOrganizationParameters = /* GraphQL */ `
  mutation CreateOrganizationParameters(
    $input: CreateOrganizationParametersInput!
    $condition: ModelOrganizationParametersConditionInput
  ) {
    createOrganizationParameters(input: $input, condition: $condition) {
      id
      name
      value
      secure
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOrganizationParameters = /* GraphQL */ `
  mutation UpdateOrganizationParameters(
    $input: UpdateOrganizationParametersInput!
    $condition: ModelOrganizationParametersConditionInput
  ) {
    updateOrganizationParameters(input: $input, condition: $condition) {
      id
      name
      value
      secure
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOrganizationParameters = /* GraphQL */ `
  mutation DeleteOrganizationParameters(
    $input: DeleteOrganizationParametersInput!
    $condition: ModelOrganizationParametersConditionInput
  ) {
    deleteOrganizationParameters(input: $input, condition: $condition) {
      id
      name
      value
      secure
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      type
      details
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      type
      details
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      type
      details
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPrice = /* GraphQL */ `
  mutation CreatePrice(
    $input: CreatePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    createPrice(input: $input, condition: $condition) {
      id
      active
      currency
      name
      paymentModel
      paymentRef
      productRef
      frequency
      scheme
      type
      depositMethod
      hourlyRate
      hours
      depositAmount
      depositPercentage
      amount
      tiers {
        name
        amount
        maxQuantity
        shareable
        shareableQuantity
        __typename
      }
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePrice = /* GraphQL */ `
  mutation UpdatePrice(
    $input: UpdatePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    updatePrice(input: $input, condition: $condition) {
      id
      active
      currency
      name
      paymentModel
      paymentRef
      productRef
      frequency
      scheme
      type
      depositMethod
      hourlyRate
      hours
      depositAmount
      depositPercentage
      amount
      tiers {
        name
        amount
        maxQuantity
        shareable
        shareableQuantity
        __typename
      }
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePrice = /* GraphQL */ `
  mutation DeletePrice(
    $input: DeletePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    deletePrice(input: $input, condition: $condition) {
      id
      active
      currency
      name
      paymentModel
      paymentRef
      productRef
      frequency
      scheme
      type
      depositMethod
      hourlyRate
      hours
      depositAmount
      depositPercentage
      amount
      tiers {
        name
        amount
        maxQuantity
        shareable
        shareableQuantity
        __typename
      }
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPurchaseSession = /* GraphQL */ `
  mutation CreatePurchaseSession(
    $input: CreatePurchaseSessionInput!
    $condition: ModelPurchaseSessionConditionInput
  ) {
    createPurchaseSession(input: $input, condition: $condition) {
      id
      organizationID
      userID
      priceID
      serviceID
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      timeSlotID
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      teamAvailabilityID
      teamAvailability {
        id
        autoConfirm
        startDate
        endDate
        bookingDuration
        bookingBufferBefore
        bookingBufferAfter
        organizationID
        serviceID
        teamIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      phoneNumber
      teamID
      scheduledTimeSlotIDs
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      startDate
      endDate
      purchased
      calendarSlotType
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePurchaseSession = /* GraphQL */ `
  mutation UpdatePurchaseSession(
    $input: UpdatePurchaseSessionInput!
    $condition: ModelPurchaseSessionConditionInput
  ) {
    updatePurchaseSession(input: $input, condition: $condition) {
      id
      organizationID
      userID
      priceID
      serviceID
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      timeSlotID
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      teamAvailabilityID
      teamAvailability {
        id
        autoConfirm
        startDate
        endDate
        bookingDuration
        bookingBufferBefore
        bookingBufferAfter
        organizationID
        serviceID
        teamIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      phoneNumber
      teamID
      scheduledTimeSlotIDs
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      startDate
      endDate
      purchased
      calendarSlotType
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePurchaseSession = /* GraphQL */ `
  mutation DeletePurchaseSession(
    $input: DeletePurchaseSessionInput!
    $condition: ModelPurchaseSessionConditionInput
  ) {
    deletePurchaseSession(input: $input, condition: $condition) {
      id
      organizationID
      userID
      priceID
      serviceID
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      timeSlotID
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      teamAvailabilityID
      teamAvailability {
        id
        autoConfirm
        startDate
        endDate
        bookingDuration
        bookingBufferBefore
        bookingBufferAfter
        organizationID
        serviceID
        teamIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      phoneNumber
      teamID
      scheduledTimeSlotIDs
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      startDate
      endDate
      purchased
      calendarSlotType
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRecommendation = /* GraphQL */ `
  mutation CreateRecommendation(
    $input: CreateRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    createRecommendation(input: $input, condition: $condition) {
      id
      organizationID
      serviceIDs
      affiliateProductIDs
      affiliateLinkIDs
      contractIDs
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRecommendation = /* GraphQL */ `
  mutation UpdateRecommendation(
    $input: UpdateRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    updateRecommendation(input: $input, condition: $condition) {
      id
      organizationID
      serviceIDs
      affiliateProductIDs
      affiliateLinkIDs
      contractIDs
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRecommendation = /* GraphQL */ `
  mutation DeleteRecommendation(
    $input: DeleteRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    deleteRecommendation(input: $input, condition: $condition) {
      id
      organizationID
      serviceIDs
      affiliateProductIDs
      affiliateLinkIDs
      contractIDs
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createResource = /* GraphQL */ `
  mutation CreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      id
      name
      description
      quantity
      organizationID
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      id
      name
      description
      quantity
      organizationID
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource(
    $input: DeleteResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    deleteResource(input: $input, condition: $condition) {
      id
      name
      description
      quantity
      organizationID
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createResourceBooking = /* GraphQL */ `
  mutation CreateResourceBooking(
    $input: CreateResourceBookingInput!
    $condition: ModelResourceBookingConditionInput
  ) {
    createResourceBooking(input: $input, condition: $condition) {
      id
      startDate
      endDate
      bookingDate
      quantity
      organizationID
      teamIDs
      timeSlotID
      resourceID
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      resource {
        id
        name
        description
        quantity
        organizationID
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateResourceBooking = /* GraphQL */ `
  mutation UpdateResourceBooking(
    $input: UpdateResourceBookingInput!
    $condition: ModelResourceBookingConditionInput
  ) {
    updateResourceBooking(input: $input, condition: $condition) {
      id
      startDate
      endDate
      bookingDate
      quantity
      organizationID
      teamIDs
      timeSlotID
      resourceID
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      resource {
        id
        name
        description
        quantity
        organizationID
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteResourceBooking = /* GraphQL */ `
  mutation DeleteResourceBooking(
    $input: DeleteResourceBookingInput!
    $condition: ModelResourceBookingConditionInput
  ) {
    deleteResourceBooking(input: $input, condition: $condition) {
      id
      startDate
      endDate
      bookingDate
      quantity
      organizationID
      teamIDs
      timeSlotID
      resourceID
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      resource {
        id
        name
        description
        quantity
        organizationID
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      active
      affiliate
      allowTeamSelection
      allowBookableTimeSelection
      autoAssignTeam
      autoConfirm
      baseType
      billingType
      billingFrequency
      bookingBufferBefore
      bookingBufferAfter
      bookingCountMax
      bookingRestrictions {
        capacity
        totalSlots
        frequency
        frequencyPeriod
        __typename
      }
      bookingType
      categoryIDs
      contentFrequency
      cost
      defaultDuration
      deliveryType
      deliveryOptions {
        platform
        url
        customerMessage
        __typename
      }
      description
      displayOrder
      featured
      formattedDescription
      handle
      images
      isDeleted
      locationType
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      languageCode
      linked
      maxAttendees
      name
      nextStepURL
      organizationID
      prices {
        items {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prohibitedSubscriptions
      requiredSubscriptions
      registrationType
      scheduleHorizon
      suppressScheduleUpdate
      serviceSchedule {
        startDate
        endDate
        serviceHours {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        __typename
      }
      serviceAvailability {
        day
        is24Hours
        window {
          startTime
          endTime
          __typename
        }
        __typename
      }
      stripePrices {
        id
        active
        currency
        product
        recurring {
          aggregate_usage
          interval
          interval_count
          usage_type
          trial_period_days
          __typename
        }
        type
        unit_amount
        billing_scheme
        created
        livemode
        lookup_key
        metadata
        nickname
        object
        tax_behavior
        tiers_mode
        transform_quantity
        unit_amount_decimal
        __typename
      }
      tagline
      teamIDs
      thumbnail
      timezone
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      active
      affiliate
      allowTeamSelection
      allowBookableTimeSelection
      autoAssignTeam
      autoConfirm
      baseType
      billingType
      billingFrequency
      bookingBufferBefore
      bookingBufferAfter
      bookingCountMax
      bookingRestrictions {
        capacity
        totalSlots
        frequency
        frequencyPeriod
        __typename
      }
      bookingType
      categoryIDs
      contentFrequency
      cost
      defaultDuration
      deliveryType
      deliveryOptions {
        platform
        url
        customerMessage
        __typename
      }
      description
      displayOrder
      featured
      formattedDescription
      handle
      images
      isDeleted
      locationType
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      languageCode
      linked
      maxAttendees
      name
      nextStepURL
      organizationID
      prices {
        items {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prohibitedSubscriptions
      requiredSubscriptions
      registrationType
      scheduleHorizon
      suppressScheduleUpdate
      serviceSchedule {
        startDate
        endDate
        serviceHours {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        __typename
      }
      serviceAvailability {
        day
        is24Hours
        window {
          startTime
          endTime
          __typename
        }
        __typename
      }
      stripePrices {
        id
        active
        currency
        product
        recurring {
          aggregate_usage
          interval
          interval_count
          usage_type
          trial_period_days
          __typename
        }
        type
        unit_amount
        billing_scheme
        created
        livemode
        lookup_key
        metadata
        nickname
        object
        tax_behavior
        tiers_mode
        transform_quantity
        unit_amount_decimal
        __typename
      }
      tagline
      teamIDs
      thumbnail
      timezone
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      active
      affiliate
      allowTeamSelection
      allowBookableTimeSelection
      autoAssignTeam
      autoConfirm
      baseType
      billingType
      billingFrequency
      bookingBufferBefore
      bookingBufferAfter
      bookingCountMax
      bookingRestrictions {
        capacity
        totalSlots
        frequency
        frequencyPeriod
        __typename
      }
      bookingType
      categoryIDs
      contentFrequency
      cost
      defaultDuration
      deliveryType
      deliveryOptions {
        platform
        url
        customerMessage
        __typename
      }
      description
      displayOrder
      featured
      formattedDescription
      handle
      images
      isDeleted
      locationType
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      languageCode
      linked
      maxAttendees
      name
      nextStepURL
      organizationID
      prices {
        items {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prohibitedSubscriptions
      requiredSubscriptions
      registrationType
      scheduleHorizon
      suppressScheduleUpdate
      serviceSchedule {
        startDate
        endDate
        serviceHours {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        __typename
      }
      serviceAvailability {
        day
        is24Hours
        window {
          startTime
          endTime
          __typename
        }
        __typename
      }
      stripePrices {
        id
        active
        currency
        product
        recurring {
          aggregate_usage
          interval
          interval_count
          usage_type
          trial_period_days
          __typename
        }
        type
        unit_amount
        billing_scheme
        created
        livemode
        lookup_key
        metadata
        nickname
        object
        tax_behavior
        tiers_mode
        transform_quantity
        unit_amount_decimal
        __typename
      }
      tagline
      teamIDs
      thumbnail
      timezone
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      email
      handle
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      teammateIDs
      teammates {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      email
      handle
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      teammateIDs
      teammates {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      email
      handle
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      teammateIDs
      teammates {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTeamAvailability = /* GraphQL */ `
  mutation CreateTeamAvailability(
    $input: CreateTeamAvailabilityInput!
    $condition: ModelTeamAvailabilityConditionInput
  ) {
    createTeamAvailability(input: $input, condition: $condition) {
      id
      autoConfirm
      startDate
      endDate
      bookingDuration
      bookingBufferBefore
      bookingBufferAfter
      organizationID
      serviceID
      teamIDs
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTeamAvailability = /* GraphQL */ `
  mutation UpdateTeamAvailability(
    $input: UpdateTeamAvailabilityInput!
    $condition: ModelTeamAvailabilityConditionInput
  ) {
    updateTeamAvailability(input: $input, condition: $condition) {
      id
      autoConfirm
      startDate
      endDate
      bookingDuration
      bookingBufferBefore
      bookingBufferAfter
      organizationID
      serviceID
      teamIDs
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTeamAvailability = /* GraphQL */ `
  mutation DeleteTeamAvailability(
    $input: DeleteTeamAvailabilityInput!
    $condition: ModelTeamAvailabilityConditionInput
  ) {
    deleteTeamAvailability(input: $input, condition: $condition) {
      id
      autoConfirm
      startDate
      endDate
      bookingDuration
      bookingBufferBefore
      bookingBufferAfter
      organizationID
      serviceID
      teamIDs
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTeammate = /* GraphQL */ `
  mutation CreateTeammate(
    $input: CreateTeammateInput!
    $condition: ModelTeammateConditionInput
  ) {
    createTeammate(input: $input, condition: $condition) {
      id
      name
      email
      handle
      phone
      hide
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      picture
      organizationIDs
      roles {
        organizationID
        roles
        __typename
      }
      baseType
      googleCalendarConfig
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTeammate = /* GraphQL */ `
  mutation UpdateTeammate(
    $input: UpdateTeammateInput!
    $condition: ModelTeammateConditionInput
  ) {
    updateTeammate(input: $input, condition: $condition) {
      id
      name
      email
      handle
      phone
      hide
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      picture
      organizationIDs
      roles {
        organizationID
        roles
        __typename
      }
      baseType
      googleCalendarConfig
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTeammate = /* GraphQL */ `
  mutation DeleteTeammate(
    $input: DeleteTeammateInput!
    $condition: ModelTeammateConditionInput
  ) {
    deleteTeammate(input: $input, condition: $condition) {
      id
      name
      email
      handle
      phone
      hide
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      picture
      organizationIDs
      roles {
        organizationID
        roles
        __typename
      }
      baseType
      googleCalendarConfig
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTimeSlot = /* GraphQL */ `
  mutation CreateTimeSlot(
    $input: CreateTimeSlotInput!
    $condition: ModelTimeSlotConditionInput
  ) {
    createTimeSlot(input: $input, condition: $condition) {
      id
      title
      description
      startDate
      endDate
      type
      maxAttendees
      attendingContracts
      deliveryType
      deliveryOptions {
        platform
        url
        customerMessage
        __typename
      }
      registrationType
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      organizationID
      serviceID
      teamID
      resourceIDs
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      details
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTimeSlot = /* GraphQL */ `
  mutation UpdateTimeSlot(
    $input: UpdateTimeSlotInput!
    $condition: ModelTimeSlotConditionInput
  ) {
    updateTimeSlot(input: $input, condition: $condition) {
      id
      title
      description
      startDate
      endDate
      type
      maxAttendees
      attendingContracts
      deliveryType
      deliveryOptions {
        platform
        url
        customerMessage
        __typename
      }
      registrationType
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      organizationID
      serviceID
      teamID
      resourceIDs
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      details
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTimeSlot = /* GraphQL */ `
  mutation DeleteTimeSlot(
    $input: DeleteTimeSlotInput!
    $condition: ModelTimeSlotConditionInput
  ) {
    deleteTimeSlot(input: $input, condition: $condition) {
      id
      title
      description
      startDate
      endDate
      type
      maxAttendees
      attendingContracts
      deliveryType
      deliveryOptions {
        platform
        url
        customerMessage
        __typename
      }
      registrationType
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      organizationID
      serviceID
      teamID
      resourceIDs
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      details
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWebsite = /* GraphQL */ `
  mutation CreateWebsite(
    $input: CreateWebsiteInput!
    $condition: ModelWebsiteConditionInput
  ) {
    createWebsite(input: $input, condition: $condition) {
      id
      name
      handle
      url
      template
      hide
      release
      content
      images
      palette {
        primary {
          main
          dark
          light
          contrastText
          __typename
        }
        secondary {
          main
          dark
          light
          contrastText
          __typename
        }
        success {
          main
          dark
          light
          contrastText
          __typename
        }
        info {
          main
          dark
          light
          contrastText
          __typename
        }
        warning {
          main
          dark
          light
          contrastText
          __typename
        }
        error {
          main
          dark
          light
          contrastText
          __typename
        }
        text {
          primary
          secondary
          disabled
          buttonText
          __typename
        }
        background {
          default
          paper
          __typename
        }
        __typename
      }
      privacyPolicy
      termsAndConditions
      config
      testimonials {
        serviceID
        avatar
        customer
        comment
        createdAt
        company
        __typename
      }
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWebsite = /* GraphQL */ `
  mutation UpdateWebsite(
    $input: UpdateWebsiteInput!
    $condition: ModelWebsiteConditionInput
  ) {
    updateWebsite(input: $input, condition: $condition) {
      id
      name
      handle
      url
      template
      hide
      release
      content
      images
      palette {
        primary {
          main
          dark
          light
          contrastText
          __typename
        }
        secondary {
          main
          dark
          light
          contrastText
          __typename
        }
        success {
          main
          dark
          light
          contrastText
          __typename
        }
        info {
          main
          dark
          light
          contrastText
          __typename
        }
        warning {
          main
          dark
          light
          contrastText
          __typename
        }
        error {
          main
          dark
          light
          contrastText
          __typename
        }
        text {
          primary
          secondary
          disabled
          buttonText
          __typename
        }
        background {
          default
          paper
          __typename
        }
        __typename
      }
      privacyPolicy
      termsAndConditions
      config
      testimonials {
        serviceID
        avatar
        customer
        comment
        createdAt
        company
        __typename
      }
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWebsite = /* GraphQL */ `
  mutation DeleteWebsite(
    $input: DeleteWebsiteInput!
    $condition: ModelWebsiteConditionInput
  ) {
    deleteWebsite(input: $input, condition: $condition) {
      id
      name
      handle
      url
      template
      hide
      release
      content
      images
      palette {
        primary {
          main
          dark
          light
          contrastText
          __typename
        }
        secondary {
          main
          dark
          light
          contrastText
          __typename
        }
        success {
          main
          dark
          light
          contrastText
          __typename
        }
        info {
          main
          dark
          light
          contrastText
          __typename
        }
        warning {
          main
          dark
          light
          contrastText
          __typename
        }
        error {
          main
          dark
          light
          contrastText
          __typename
        }
        text {
          primary
          secondary
          disabled
          buttonText
          __typename
        }
        background {
          default
          paper
          __typename
        }
        __typename
      }
      privacyPolicy
      termsAndConditions
      config
      testimonials {
        serviceID
        avatar
        customer
        comment
        createdAt
        company
        __typename
      }
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
